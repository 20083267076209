import { readCookie } from "./cookies";
import { jwtDecode } from "jwt-decode";

/**
 * Get the Auth0 JWT contents
 * @returns {object|null}
 */
export function getUserData() {
  const auth0Cookie = document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((c) => c.startsWith("sa_piano_auth"))[0];

  if (!auth0Cookie) {
    return null;
  }

  const cookieValue = auth0Cookie.split("=")[1];
  const data = jwtDecode(cookieValue);
  return data;
}

/***
 * Get the Chargebee user entitlements from the SciAm JWT
 * @returns {null|string[]} List of user entitlements
 */
export function getUserEntitlements() {
  const auth0_jwt = getUserData();
  const sciam_jwt_key = `sa_user.${auth0_jwt.aud}`;
  const cookieValue = readCookie(sciam_jwt_key);
  if (!cookieValue) {
    return null;
  }
  const data = jwtDecode(cookieValue);
  return data?.features ?? null;
}
